.post-detail-box{text-align: left;}
.top-details a{color: #333;
    font-family: "ARIAL";
    font-weight: 400;
    font-size: 20px;}
.top-details p{font-size: 14px; margin-bottom: 0px;}
.post-detail-box .top-details ul{position: relative;}
.post-detail-box .top-details ul li:last-child{position: absolute; top: 50%; right: 0; transform: translateY(-50%);}
.post-detail-box .top-details ul li img {    width: 45px;
    height: 45px;
    border-radius: 50%;
    }
.post-box-parent{position: relative;}
.post-slider-box-bottom {  bottom: 0; width: 100%; left: 0; box-sizing: border-box;}
.post-slider-box-bottom-ul{width: 100%; text-align: left;}
.post-slider-box-bottom-ul li a span{color:#000; padding-left: 5px;}
.post-slider-box-bottom{ overflow: hidden;}
.videoInner{ width:100%; }
.video-player { position: relative; box-sizing: border-box; }
.edit-delete-option .edit-option {
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #808080;
}
.edit-delete-option {
    display: flex;
    margin-top: 0.5rem;
}
.edit-delete-option button {
    border: none;
    cursor: pointer;
    background: none;
}
.edit-delete-option button img {
    width: 15px;
    height: 15px;
    opacity: 0.7;
    transition: 0.3s;
}
