@media only screen and (min-width: 150px) and (max-width: 990px) {
  .side-fixed-panel-inner {
    width: 100%;
    border-radius: 0px;
    padding: 20px 15px;
  }
  .back-btn a {
    left: 2rem;
  }
  .back-btn.hashtags-details .back-btn {
    top: 1rem;
  }

  .activity-timeline .profile-page-header a {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 10px;
  }
  .activity-timeline .desk-none {
    display: none;
  }
  .panel-profile .img-sec {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .panel-profile .img-sec img {
    width: 100%;
  }
  .profile-page-head-content-inner li:first-child {
    padding-left: 6rem;
  }

  .full-sec {
    padding-bottom: 65px;
  }
  .full-sec .right-sec .full-box {
    padding-top: 20px;
  }
  .full-sec .left-sec {
    display: none;
  }
  .full-sec .right-sec {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .discover-top {
    padding-top: 50px !important;
  }

  .post-box-parent {
    padding: 0px;
  }
  .post-detail-box-parent {
    padding: 15px;
  }
  .top-profile-box {
    margin-bottom: 20px;
  }
  .top-profile-box h4 {
    display: none;
  }
  .top-profile-box ul {
    margin: 0px;
  }
  .full-post-box {
    box-shadow: 0px 3px 10px #0000001a;
  }
  .post-slider-box-bottom {
    box-shadow: none;
  }

  .res-bootom-bar {
    position: fixed;
    bottom: -10px;
    left: 0;
    width: 100%;
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.12);
    background: #fff;
    padding: 9px 28px 0px 28px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    display: block;
    height: 57px;
    z-index: 100;
  }
  .res-bootom-bar-ul {
    list-style: none;
    margin-bottom: 0px;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }
  .res-bootom-bar-ul li {
    display: inline-block;
    width: 20%;
    text-align: center;
  }
  /*.res-bootom-bar-ul li:nth-child(2){padding-right: 35%;}*/
  .res-bootom-bar-ul li:last-child {
    padding-right: 0px;
  }
  .res-bootom-bar-ul li img {
    width: 20px;
  }

  .res-bootom-bar-ul li.creator-button {
    position: relative;
    margin: 0px !important;
    top: -26px;
  }
  .res-bootom-bar-ul li.creator-button img {
    width: 56px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0px -1px 3px 0px rgb(0 0 0 / 12%);
  }

  .ordering {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* optional */
  }

  .first_div {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .second_div {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .post-detail-box-parent .post-detail-box hr {
    display: none;
  }
  .post-detail-box-parent .post-detail-box .bottom-deatails {
    display: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 990px) {
  .tab-res-none {
    display: none;
  }
  .sign-body {
    margin-top: 80px;
  }
  .reset-password-header .sign-ul-parent {
    display: none;
  }
  .full-post-box {
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 35px;
  }
  .top-profile-box ul li img {
    width: 80px !important;
    height: 80px;
    margin: auto;
  }
  .res-bootom-bar-ul li.creator-button img {
    width: 67px;
  }
  .full-sec .right-sec .full-box {
    padding-bottom: 100px;
  }
  .comment-dots {
    display: none;
  }
  .cat-box .row {
    margin: 0px;
  }
  .cat-box .row .box {
    padding: 0px;
  }
  .profile-page-head-content-inner li {
    padding: 0px 23px;
  }
  .panel-form input[type="text"] {
    padding-left: 8px;
  }
  .mobile-nav {
    margin-right: 2rem;
    margin-top: 2rem;
  }
  .panel-form textarea {
    padding-left: 8px;
    padding-top: 8px;
  }
  .side-fixed-panel-inner.account-setting.open input[type="submit"] {
    bottom: 2rem !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .page-search-box {
    width: 100%;
    padding: 0px 15px;
    top: 10px;
    left: 0px;
  }
  .discover-content {
    padding: 0px 15px !important;
  }
  .discover-content .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .discover-content.right-sec .full-box {
    padding-bottom: 60px;
  }
  .home-feed-full-box {
    padding-bottom: 0px !important;
  }
}

@media only screen and (min-width: 150px) and (max-width: 500px) {
  .inner-box {
    /* margin-top: 30px; */
    margin-left: 20px !important;
    /* margin-right: 20px !important; */
  }
  .head-part h4 {
    margin-top: 0px;
  }
  .head-part li:last-child a {
    font-size: 14px;
  }
  .head-part li:last-child a img {
    width: 11px;
  }

  .cat-box .row .box img {
    height: 123px;
  }
  .cat-box .row .box {
    padding: 0px;
  }
  .hashtag-ul li a {
    font-size: 14px;
    padding: 6px 20px;
  }

  .post-detail-box .top-details ul li h3 {
    font-size: 22px;
  }
  .full-sec .right-sec .full-box {
    padding-bottom: 0px;
  }
  .res-none {
    display: block !important;
  }
  .desk-none {
    display: block;
  }
  .res-none {
    display: none;
  }
  .container {
    width: 100%;
  }
  header .logo-sec {
    display: none;
  }
  .forgot-password-header {
    display: none;
  }
  .reset-password-header {
    display: none;
  }
  .sign-ul-parent {
    padding: 0px;
  }
  .sign-ul {
    margin-top: 0px;
  }
  .sign-ul li a.active {
    border: 0px;
    border-bottom: 1px solid #0242fc;
    border-radius: 0px;
    padding: 4px 0px;
  }
  .sign-body {
    width: 100%;
    padding: 0px 15px;
    margin-top: 40px;
  }
  .res-logo {
    margin-bottom: 30px;
  }
  .res-logo img {
    width: 66px;
  }
  .sign-body form input[type="text"] {
    height: 40px;
  }
  .sign-body form input[type="submit"] {
    height: 40px;
  }
  .sign-body form p {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sign-body h3 {
    font-size: 20px;
    text-align: center;
  }
  .sign-body .res-show-text {
    text-align: center;
  }
  .sign-body .back-arrow-btn-parent {
    margin-bottom: 40px;
  }
  .forgot-password-body {
    margin-top: 20px;
  }
  .reset-password-body {
    margin-top: 20px;
  }
  .full-post-box {
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  .profile-page-head-ul .profile-page-head-avatar {
    width: 100%;
  }
  .profile-page-head-content-inner li {
    width: 33.3%;
    float: left;
  }
  .profile-page-head-content-inner li:first-child {
    width: 100%;
    padding: 0px;
    text-align: center;
    margin-bottom: 15px;
  }
  .profile-page-head-content-inner li:nth-child(2) {
    border-left: 0px;
  }
  .profile-page-head-content-inner li {
    padding: 0px 15px;
  }
  #tabs-nav {
    text-align: center;
  }
  .profile-page-head-ul {
    text-align: center;
  }
  .profile-page-header {
    display: none;
  }

  .discover-full-box .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .discover-content {
    padding: 0px 15px !important;
  }
  .page-search-box {
    width: 100%;
    padding: 0px 15px;
    top: 10px;
    left: 0px;
  }
  .profile-box .box h5 {
    font-size: 15px;
  }
  .profile-box .box h6 {
    font-size: 10px;
  }
  .profile-box .img-box {
    width: 55px !important;
    height: 55px;
    object-fit: cover;
  }
  .profile-box .img-box img {
    width: 100% !important;
    object-fit: cover;
  }

  .profile-box .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .discover-content .row {
    margin: 0px;
  }

  .arrival-box .box {
    padding: 0px;
  }
  .arrival-box .box .img-box {
    height: 129px !important;
    overflow: hidden;
  }
  .mobile-nav {
    margin-right: 2rem;
    margin-top: 2rem;
  }
  .side-fixed-panel-inner.account-setting.open input[type="submit"] {
    bottom: 2rem !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .back-btn a {
    flex-direction: row;
    left: 2rem;
  }
  .profile-content-list.feed-detail-section .back-btn a {
    top: -4rem;
  }
  .row.tab-content {
    margin-left: 0px;
    margin-right: 0px;
  }

  .userVideoData .box {
    padding: 7px !important;
    margin-bottom: 0px;
  }
  .profile-page-head-content {
    width: 100%;
    padding-left: 0px;
  }

  .panel-form input[type="text"] {
    height: 40px;
    padding: 0px 8px;
  }
  .panel-form textarea {
    padding: 8px 0px 0px 8px;
  }
  .panel-form .custom-form-group {
    margin-bottom: 15px;
  }

  .hashtag-ul li {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .discover-content {
    padding-top: 55px !important;
  }

  .notification-full-box {
    padding: 0px 15px;
  }
  .notification-box {
    padding: 15px;
  }
  .notification-box-ul li.content-sec {
    width: 82%;
    padding-right: 10px;
  }
  .notification-box-ul li.img-sec {
    width: 18%;
  }
  .notification-box-ul li.img-sec img {
    width: 100%;
    max-height: auto;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1439px) {
  .full-sec .left-sec {
    width: 27% !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .profile-page-head-content-inner li:first-child {
    padding-left: 5rem;
  }
  .profile-page-head-content-inner li:last-child {
    padding: 0px 25px;
  }
}
